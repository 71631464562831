// getShouldShowPasswordBindingHint.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getMeData from '../selector/getMeData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import { getIsChinaPartner } from '../resource/partner.js';

/**
 * Select should show password binding hint by
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {RETURN_TYPE} The selected should show password binding hint.
 */
const getShouldShowPasswordBindingHint = createCachedSelector(
  state => !!getNetworkingData(state, ['me', 'accountLinks'], 'isFetched'),
  state => !!getMeData(state, 'isPasswordBound'),
  (isAccountLinksFetched, isPasswordBound) => {
    const isChinaPartner = getIsChinaPartner();
    return isAccountLinksFetched && !isPasswordBound && !isChinaPartner;
  }
)(() => 'getShouldShowPasswordBindingHint');

export default getShouldShowPasswordBindingHint;
