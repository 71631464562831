// unsubscribeFeedChannel.js
'use strict';
import * as MessageTypes from '../serviceWorker/AppMessageTypes.js';
import { sendMessageToSW } from '../serviceWorker/helpers.js';

/**
 * Unsubscribe feed channel
 * @kind action
 * @param {string} {feedName} - feed name.
 * @return {Promise} Action promise.
 */
const unsubscribeFeedChannel =
  ({ feedName }) =>
  async dispatch => {
    if (!feedName) {
      return dispatch({ type: '' });
    }
    return sendMessageToSW({
      type: MessageTypes.UNSUBSCRIBE_FEED_CHANNEL,
      payload: {
        feedName,
      },
    });
  };

export default unsubscribeFeedChannel;
