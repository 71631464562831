// openUploadMenuModal.js
'use strict';

import { JWT_SCOPE_CREATOR } from '../RemoteConfigKeys.js';

import addModal from '../action/addModal.js';

import getIsPermissionMatched from '../selector/getIsPermissionMatched.js';

/**
 * Open upload menu modal
 * @kind action
 * @return {Promise} Action promise.
 */
const openUploadMenuModal = () => async (dispatch, getState) => {
  const isCP = getIsPermissionMatched(getState(), JWT_SCOPE_CREATOR);
  if (!isCP) {
    return dispatch(
      addModal({
        id: 'BecomeCreatorModal',
        transitionStatus: 'opening',
      })
    );
  }
  return dispatch(
    addModal({
      id: 'UploadMenuModal',
      transitionStatus: 'opening',
    })
  );
};

export default openUploadMenuModal;
