// getSelectedFeedQueryOptions.js
'use strict';
import flatten from 'lodash/flatten';

import { getCandidateQueryOptionObject } from '../selector/getFeedQueryObject.js';
import getRouterData from '../selector/getRouterData.js';

import createCachedSelector, {
  createSelector,
} from '../resource/createCachedSelector.js';
import { parseQueryOptions } from '../resource/feedUtils.js';
import { OPTION_UNSET, QUERY_SEPARATOR } from '../resource/feedConstants.js';

const defaultObject = {};
const defaultArray = [];

/**
 * Get selected feed query options by remote config key name and query type.
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} remoteConfigKeyName - remote config key.
 * @param {QueryType} queryType - query type.
 * @return {array} Return selected query options.
 */
const getSelectedFeedQueryOptions = createCachedSelector(
  (state, remoteConfigKeyName, queryType) =>
    getCandidateQueryOptionObject(state, remoteConfigKeyName, queryType),
  state => getFeedQueryOptionsFromUrl(state),
  (optionGroupObject = defaultObject, queries = defaultArray) => {
    const {
      intersectedFeedNames: selectedIntersectedFeedNames,
      filters: selectedFilters,
      sorts: selectedSorts,
    } = parseQueryOptions({ options: queries });
    const selectedOptions = flatten(Object.values(optionGroupObject)).filter(
      option => {
        if (!option || OPTION_UNSET === option) {
          return false;
        }
        let isFeedMatched = false;
        let isFiltersMatched = false;
        let isSortsMatched = false;
        const {
          intersectedFeedNames: optionIntersectedFeedNames,
          filters: optionFilters,
          sorts: optionSorts,
        } = parseQueryOptions({ options: [option] });
        isFeedMatched =
          !selectedIntersectedFeedNames.length &&
          !optionIntersectedFeedNames.length
            ? true
            : optionIntersectedFeedNames.every(name =>
                selectedIntersectedFeedNames.includes(name)
              );
        isFiltersMatched = optionFilters.every(filter =>
          selectedFilters.includes(filter)
        );
        isSortsMatched = optionSorts.every(sort =>
          selectedSorts.includes(sort)
        );
        return isFeedMatched && isFiltersMatched && isSortsMatched;
      }
    );
    return selectedOptions;
  }
)(
  (state, remoteConfigKeyName, queryType) =>
    `${remoteConfigKeyName}:${queryType}`
);

export default getSelectedFeedQueryOptions;

/**
 * Select queries from url
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {array} The selected queries from url.
[
  'user_livestream-global-filtered&user_creators',
  '?filters=region:asia',
  '?filters=region:naeu',
  '?filters=preset:preview',
  '?filters=preset:sd',
]
 */
export const getFeedQueryOptionsFromUrl = createSelector(
  state => getRouterData(state, 'location.pathname'),
  state => getRouterData(state, 'location.search'),
  (pathname, search) => {
    const urlObject = new URL(`${pathname}${search}`, 'https://localhost');
    const feed = (
      urlObject.searchParams.get('feed') ||
      urlObject.searchParams.get('_feed') ||
      ''
    )
      .split(QUERY_SEPARATOR)
      .filter(name => name)
      .join('&');
    const filters = (
      urlObject.searchParams.get('filters') ||
      urlObject.searchParams.get('_filters') ||
      ''
    )
      .split(QUERY_SEPARATOR)
      .filter(query => query)
      .map(query => `?filters=${query}`);
    const sorts = (
      urlObject.searchParams.get('sorts') ||
      urlObject.searchParams.get('_sorts') ||
      ''
    )
      .split(QUERY_SEPARATOR)
      .filter(query => query)
      .map(query => `?sorting=${query}`);
    const queries = [feed, ...filters, ...sorts].filter(item => item);
    return queries;
  }
);
