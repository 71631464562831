// getFeedRenewData.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getOperationData from '../selector/getOperationData.js';

const defaultObject = {};

/**
 * Select feed renew object by feed name
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} feedName - feed name string, can concat feed name by & sign, like: feed_a&feed_b.
 * @param {string} dataKey - data key.
 * @return {object|undefined} Return feed renew object if dataKey is empty, or return expected dataKey value.
 */
const getFeedRenewData = createCachedSelector(
  state => getOperationData(state, ['renewFeedId']),
  (state, feedName) => feedName,
  (state, feedName, dataKey) => dataKey,
  (renewFeedObject = defaultObject, feedName = '', dataKey) => {
    const feedNames = feedName.split('&').filter(name => name);
    const feedObject = feedNames.reduce((accumulator, name) => {
      accumulator[name] = {
        ...(renewFeedObject[name] || {}),
        feedName: name,
      };
      return accumulator;
    }, {});
    if (!dataKey) {
      return feedObject;
    }
    const values = feedNames.map(name => {
      return renewFeedObject[name]?.[dataKey];
    });
    const isAllExisted = values.every(value => value != null);
    if (['timestamp', 'id'].includes(dataKey)) {
      if ('timestamp' === dataKey) {
        const result = values.sort((a, b) => b - a)[0];
        return result;
      }
      if ('id' === dataKey) {
        if (!isAllExisted) {
          return;
        }
        const result = values.join('&');
        return result;
      }
    }
  }
)((state, feedName, dataKey) => `${feedName}:${dataKey}`);

export default getFeedRenewData;
