// NavHeader.js
import { connect } from 'react-redux';
import { goBack } from 'redux-first-history';

import {
  HOME_FEATURE_CATEGORIES,
  LIVESTREAM_FEED,
  LIVESTREAM_FEED_SORTINGS,
  SHORTS_FEED_ALIAS,
  LIVESTREAM_CLIP_FEED_ALIAS,
  LEADERBOARD_IDS,
  LEADERBOARD_CATEGORIES,
  BROWSE_TABS,
  BRANDING_NAME,
} from '../RemoteConfigKeys.js';

import getMeData from '../selector/getMeData.js';
import getShouldShowPasswordBindingHint from '../selector/getShouldShowPasswordBindingHint.js';
import getModalData from '../selector/getModalData.js';
import getOperationData from '../selector/getOperationData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { getSelectedQueryOptionObject } from '../selector/getFeedQueryObject.js';
import getSelectedFeedQueryOptions from '../selector/getSelectedFeedQueryOptions.js';
import getFeedRenewData from '../selector/getFeedRenewData.js';

import openUploadMenuModal from '../action/openUploadMenuModal.js';
import toggleModal from '../action/toggleModal.js';
import addvalue from '../action/addvalue.js';
import fetchFeeds from '../action/fetchFeeds.js';
import subscribeFeedChannel from '../action/subscribeFeedChannel.js';
import unsubscribeFeedChannel from '../action/unsubscribeFeedChannel.js';

import { addvalue as addvalueModalIds } from '../resource/modalId.js';
import { SEARCH_MODAL_ID } from '../resource/searchConstants.js';

import NavHeader from '../component/NavHeader.jsx';

const mapStateToProps = state => {
  const meId = getMeData(state, 'id');
  const isAuthed = !!meId;
  const meUsername = getMeData(state, 'username');
  const homeCategories = getRemoteConfigData(state, HOME_FEATURE_CATEGORIES);
  const lastLivestreamFeed = homeCategories.find(
    (category, index) =>
      homeCategories.length - 1 === index &&
      /^livestream_|^user_/.test(category)
  );
  const livestreamFeedName = lastLivestreamFeed;
  const { intersectedFeedName, feedNameWithQuery } =
    getSelectedQueryOptionObject(
      state,
      livestreamFeedName,
      getSelectedFeedQueryOptions(state, LIVESTREAM_FEED, undefined),
      getRemoteConfigData(state, LIVESTREAM_FEED_SORTINGS)?.[0]
    );
  return {
    clipCategory: getRemoteConfigData(state, LIVESTREAM_CLIP_FEED_ALIAS),
    meUsername,
    feedNameWithQuery,
    renewFeedId: getFeedRenewData(state, intersectedFeedName, 'id'),
    latestFeedRenewUnix: getFeedRenewData(
      state,
      intersectedFeedName,
      'timestamp'
    ),
    deviceWidth: getOperationData(state, ['device'], 'width'),
    isAuthed,
    hasUnread: getMeData(state, 'hasUnread'),
    shouldShowPasswordBindingHint: getShouldShowPasswordBindingHint(state),
    isHamburgerMenuOpen: ['display', 'opening'].includes(
      getModalData(state, 'HamburgerMenuModal', 'status')
    ),
    isDiamondShopOpen: !!['display', 'opening'].includes(
      getModalData(state, addvalueModalIds.DIAMOND_PACK_SELECTOR, 'status')
    ),
    isBecomeCreatorModalOpen: ['display', 'opening'].includes(
      getModalData(state, 'BecomeCreatorModal', 'status')
    ),
    isBackpackModalOpen: ['display', 'opening'].includes(
      getModalData(state, 'BackpackModal', 'status')
    ),
    isUploadMenuModalOpen: ['display', 'opening'].includes(
      getModalData(state, 'UploadMenuModal', 'status')
    ),
    isNotificationCenterOpened: ['display', 'opening'].includes(
      getModalData(state, 'NotificationCenterModal', 'status')
    ),
    isSearchModalOpened: ['display', 'opening'].includes(
      getModalData(state, SEARCH_MODAL_ID, 'status')
    ),
    isShortEnabled: !!getRemoteConfigData(state, SHORTS_FEED_ALIAS),
    leaderboardRegions: getRemoteConfigData(state, LEADERBOARD_IDS),
    leaderboardCategories: getRemoteConfigData(state, LEADERBOARD_CATEGORIES),
    browseTabs: getRemoteConfigData(state, BROWSE_TABS),
    brandname: getRemoteConfigData(state, BRANDING_NAME),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goBack: () => dispatch(goBack()),
    openUploadMenuModal: () => dispatch(openUploadMenuModal()),
    toggleModal: ({ modalId, payload, isOpen, modalOption }) =>
      dispatch(toggleModal({ modalId, payload, isOpen, modalOption })),
    addvalue: () => dispatch(addvalue({ via: 'direct' })),
    fetchFeeds: payload => dispatch(fetchFeeds(payload)),
    subscribeFeedChannel: data => dispatch(subscribeFeedChannel(data)),
    unsubscribeFeedChannel: data => dispatch(unsubscribeFeedChannel(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavHeader);
