// addvalue.js
'use strict';
import addModal from '../action/addModal.js';
import { addvalue as addvalueModalIds } from '../resource/modalId.js';
import trackEvent, { SHOP_OPENED } from '../resource/trackEvent.js';
import { SHOP_ID } from '../resource/shopConstants.js';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Addvalue
 * @kind action
 * @param {string} [{shopId} = 'addvalue'] - shop id.
 * @param {string} [{via} = 'direct'] - start addvalue condition.
 *   ['direct', 'sufficient', 'insuficient']
 * @return {Promise} Action promise.
 */
const addvalue =
  ({ shopId = SHOP_ID.addvalue, via = 'direct' } = {}) =>
  async dispatch => {
    trackEvent({ type: SHOP_OPENED, payload: { via, type: 'diamonds' } });

    const modalId = addvalueModalIds.DIAMOND_PACK_SELECTOR;

    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['shop'],
        data: {
          usingShopId: shopId,
        },
      },
    });

    return dispatch(
      addModal({
        id: modalId,
        isHigherThanAll: true,
        transitionStatus: 'coming',
      })
    );
  };

export default addvalue;
