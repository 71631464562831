// getFeedQueryObject.js
'use strict';
import { Map } from 'immutable';

import getOperationData from '../selector/getOperationData.js';

import createCachedSelector from '../resource/createCachedSelector.js';
import {
  OPTION_UNSET,
  QueryType,
  QueryPrefix,
} from '../resource/feedConstants.js';
import { parseQueryOptions } from '../resource/feedUtils.js';

const defaultMap = new Map();
const defaultArray = [];

/**
 * Get selected option object whihch contain feed name, querys and sorts.
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} baseFeedName - base feed name.
 * @param {array} selectedQueryOptions - selected query options.
 * @param {string} defaultSorting - default sorting.
 * @return {object} Return parsed selected option object.
{
  intersectedFeedName:
    'user_creators&user_following_ME&user_livestream-global&user_livestream-global-filtered',
  intersectedFeedNames: [
    'user_creators',
    'user_following_ME',
    'user_livestream-global',
    'user_livestream-global-filtered',
  ],
  filters: ['preset:preview', 'preset:sd', 'region:asia', 'region:naeu'],
  sorts: [],
  feedNameWithQuery: 'user_creators&user_livestream-global?filters=preset:preview'
}
 */
export const getSelectedQueryOptionObject = createCachedSelector(
  (state, baseFeedName) => baseFeedName,
  (state, baseFeedName, selectedQueryOptions) => selectedQueryOptions,
  (state, baseFeedName, selectedQueryOptions, defaultSorting) => defaultSorting,
  (baseFeedName = '', selectedQueryOptions = defaultArray, defaultSorting) => {
    const tasks = [baseFeedName, ...selectedQueryOptions];
    const hasSorting = tasks.some(option => option.includes('sorting='));
    if (!hasSorting && defaultSorting) {
      tasks.push(defaultSorting);
    }
    const result = parseQueryOptions({ options: tasks });
    return result;
  }
)(
  (state, baseFeedName, selectedQueryOptions, defaultSorting) =>
    `${baseFeedName}:${selectedQueryOptions}:${defaultSorting}`
);

/**
 * Get parsed selected draft option object whihch contain feed name, querys and sorts.
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} baseFeedName - base feed name.
 * @param {boolean} optionKey - selected option key.
 * @return {object|undefined} Return parsed selected option object.
{
  intersectedFeedName:
    'user_creators&user_following_ME&user_livestream-global&user_livestream-global-filtered',
  intersectedFeedNames: [
    'user_creators',
    'user_following_ME',
    'user_livestream-global',
    'user_livestream-global-filtered',
  ],
  filters: ['preset:preview', 'preset:sd', 'region:asia', 'region:naeu'],
  sorts: [],
  feedNameWithQuery: 'user_creators&user_livestream-global?filters=preset:preview'
}
 */
export const getSelectedDraftQueryOptionObject = createCachedSelector(
  (state, baseFeedName) => baseFeedName,
  (state, baseFeedName) =>
    getOperationData(
      state,
      ['feed', baseFeedName, QueryType.FILTER],
      'draftOptions'
    ),
  (state, baseFeedName) =>
    getOperationData(
      state,
      ['feed', baseFeedName, QueryType.SORT],
      'draftOptions'
    ),
  (
    baseFeedName,
    selectedFilterOptions = defaultArray,
    selectedSortOptions = defaultArray
  ) => {
    let result = {
      intersectedFeedName: '',
      intersectedFeedNames: [],
      filters: [],
      sorts: [],
      feedNameWithQuery: '',
    };
    if (!baseFeedName) {
      return result;
    }
    const tasks = [
      baseFeedName,
      ...selectedFilterOptions,
      ...selectedSortOptions,
    ];
    result = parseQueryOptions({ options: tasks });
    return result;
  }
)((state, baseFeedName) => `${baseFeedName}`);

/**
 * Select query option object by remote config key name and query type
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} remoteConfigKeyName - remote config key name.
 * @param {QueryType|undefined} queryType - query type. `undefined` means all query types.
 * @return {object|undefined} Return group object. ex:
{
  "LIVESTREAM_FEED_FILTERS_REGIONS": [
    "unset",
    "user_livestream-global&user_creator?filters=region:asia",
    "user_livestream-global&user_creator?filters=region:naeu"
  ],
  "LIVESTREAM_FEED_FILTERS_FOLLOWS": [
    "unset",
    "user_livestream-global&user_following_ME"
  ]
}
 */
export const getCandidateQueryOptionObject = createCachedSelector(
  (state, remoteConfigKeyName) => remoteConfigKeyName,
  (state, remoteConfigKeyName, queryType) => queryType,
  state => state.get('remoteConfig'),
  (remoteConfigKeyName, queryType, remoteConfigMap = defaultMap) => {
    if (!remoteConfigKeyName) {
      return;
    }
    const result = remoteConfigMap
      .filter((value, key) => {
        if (!value) {
          return false;
        }
        if (!queryType) {
          return Object.values(QueryPrefix).some(prefix =>
            key.startsWith(`${remoteConfigKeyName}_${prefix}`)
          );
        }
        return key.startsWith(
          `${remoteConfigKeyName}_${QueryPrefix[queryType]}`
        );
      })
      .reduce((accumulator, value, key) => {
        accumulator[key] = [
          OPTION_UNSET,
          ...(remoteConfigMap?.get(key)?.split(' ') || []),
        ];
        return accumulator;
      }, {});
    return result;
  }
)(
  (state, remoteConfigKeyName, queryType) =>
    `${remoteConfigKeyName}:${queryType}`
);
